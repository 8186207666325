<template>
  <div class="templates-bounding">
    <div class="templates-header">
      <div class="header-container">
        <h1 class="templates-title-text">{{ this.$t("formview.MyTemplates") }}</h1>
        <button class="templates-exit-button" @click="close">X</button>
      </div>
      <div class="tabs">
        <div
          class="tab"
          :class="{ active: activeTab === 'tab1' }"
          @click="activeTab = 'tab1'"
        >
          {{ this.$t("formview.Forms") }}
        </div>
        <div
          class="tab"
          :class="{ active: activeTab === 'tab2' }"
          @click="activeTab = 'tab2'"
        >
          {{ this.$t("formview.Coords") }}
        </div>
      </div>
    </div>

    <div class="templates-main-section">
      <div class="templates-content">
        <div class="templates-tabs-content">
          <div class="templates-tab-content">
            
            <div v-if="filteredTemplates.length == 0" class="error-message">
              {{ loadingError }}
            </div>
            <div v-else>
              <div
                v-for="template in filteredTemplates" 
                :key="template.name"
                @click="applyTemplate(template)"
                @mouseenter="onMouseEnter(template, $event)"
                @mouseleave="onMouseLeave"
                class="statistic"
              >
                <span class="stat-name">{{ template.name }}</span>

                <div class="additionalInfo" v-if="hoveredTemplate === template">
                  <div v-if="!template.values[0].category">
                    {{ $t('formview.ManualTemplate')}}
                  </div>
                  <div v-else>
                    <div v-for="(value, key) in template.values" :key="key">
                      {{ $t('formview.' + value.category) }}: {{ value.input_value }}
                    </div>
                  </div>
                </div>

                <div class="stat-value">
                  <img src="@/assets/delete-button.svg" alt="delete" @click.stop="removeTemplate(template)">
                </div>

              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'templateModal',
  data() {
    return {
      loadingError: this.$t("formview.NoTemplatesFound"),
      activeTab: "tab1",
      hoveredTemplate: null,
    };
  },
  props: {
    templates: Array,
  },
  computed: {
    filteredTemplates() {
      // Filter templates based on active tab
      if (this.activeTab === 'tab1') {
        return this.templates.filter(template => template.values[0].rect == undefined);
      } else if (this.activeTab === 'tab2') {
        return this.templates.filter(template => template.values[0].rect !== undefined);
      } else {
        return [];
      }
    }
  },
  methods: {
    close() {
      this.$emit('close');
    },
    applyTemplate(template) {
      this.close();
      if (template.values[0].rect !== undefined){
        this.$emit('apply-manual-template', template);
      }
      else{
        this.$emit('apply-template', template);
    }
    },
    removeTemplate(template) {
      this.$emit('remove-template', template);
    },
    onMouseEnter(template, event) {
      this.hoveredTemplate = template;
      this.positionAdditionalInfo(event.target.closest('.statistic'));
    },
    onMouseLeave() {
      this.hoveredTemplate = null;
    },
    positionAdditionalInfo(hoveredElement) {
      this.$nextTick(() => {
        const infoBox = this.$el.querySelector('.additionalInfo');
        if (infoBox) {
          console.log(infoBox)
          const viewportWidth = window.innerWidth;
          const boxWidth = infoBox.offsetWidth;
          const rect = hoveredElement.getBoundingClientRect();
          infoBox.style.top = `${rect.bottom + window.scrollY}px`;
          infoBox.style.left = `${(viewportWidth / 2) - (boxWidth / 2) + window.scrollX}px`;
          infoBox.style.display = 'block';
        }
      });
    }
  },
};
</script>

<style lang="scss" scoped src="@/assets/css/templateModal.scss" />
