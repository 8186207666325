<template>
    <transition>
        <LoadingOverlay :message="message"/>
    </transition>
    <ZipModal v-show="zipModalOpen" @close="closeZipModal" :zip_files="zip_data"/>
    <PasswordModal v-show="passwordModal" @close="closePasswordModal" 
    :docName = "docName" 
    :isEncrypted = "isEncrypted" 
    :documentId = "documentId"/>
    <div class="modalBack">
        <div class="flex">
            <div class="flexH1">
                <img src="../assets/iconDocuments.svg" alt="doc. icon" class="docIcon">
                <h1> {{ $t("menu.downloadDocuments") }} </h1>
            </div>
        </div>
        <div class="columnFlex">    
            <div class="columnFlexItem">
                <h3> {{ $t("downloadview.filename") }} </h3>
            </div>
            <div class="columnFlexItem">
                <h3> {{ $t("downloadview.date") }} </h3>
            </div>
            <div class="columnFlexItem" style="width:5%"></div>
            <div class="columnFlexItem">
                <h3> {{ $t("downloadview.entitylist") }} </h3>  
            </div>
            <div class="columnFlexItem">
                <h3> Heslo </h3>  
            </div>
            <div class="columnFlexItem">
                <h3> {{ $t("downloadview.anonymizedfile") }} </h3>  
            </div>
        </div>
        <div class="columnFlex" v-for="(document, index) in anonymizedFiles" :key="index">
            <div class="columnFlexItem" id='filename'>
                <label>{{document.originalfilename}}</label>
            </div>
            <div class="columnFlexItem" id="date">
                <label>{{explode(document.dtcreated)}}</label>
            </div>
            <div class="columnFlexItem" id="alert">
                <label v-if="document.deletionalert" class="tooltip" id="deletionalert">
                    !
                    <span class="tooltiptext"> 
                        {{ $t("downloadview.deletionAlert") }}
                    </span>
                </label>
            </div>
            <div class="columnFlexItem" id="getCsv">
                <a v-if="document.csvurl" @click="fetchDocCSV(document.csvurl)" class="downloadLinks" target="_blank" > {{ $t("downloadview.csvdownload") }} </a>
            </div>
            <div class="columnFlexItem" id="encryptDoc">
                <img class="passwordImg" v-if="typeof document.encrypted !== 'undefined' && document.encrypted" @click="showPasswordModal(document.originalfilename, document.id, document.encrypted)" src="../assets/locked_document.svg" alt="">
                <img class="passwordImg" v-else-if="typeof document.encrypted !== 'undefined' && !document.encrypted" @click="showPasswordModal(document.originalfilename, document.id, document.encrypted)" src="../assets/unlocked_document.svg" alt="">
            </div>
            <div class="columnFlexItem deleteItem">

                <div v-if="document.zip_files" id="showFiles">
                    <img @click="showZipModal(document.zip_files)" src="../assets/view-files.png">
                </div>
                <div v-else style="width: 25px;"></div>
                <a @click="fetchSingleDoc(document.url, document.originalfilename)" target="_blank" class="downloadLinks" > {{ $t("downloadview.download") }} </a>
                <label @click="deleteFileFromFacade(document)" class="deleteFile"><img src="../assets/NOT_icon.svg" class="notIcon"></label>
                
            </div>
        </div>
        
        <div class="flexButton">
            <button class="downloadButton" @click="fetchZip" target="_blank"> {{ $t("downloadview.downloadAllAsZip") }} </button>
        </div>
        
    </div>

</template>


<script>
import { useCookies } from 'vue3-cookies'
import EnvProvider from 'jvjr-docker-env';
import LoadingOverlay from '../components/LoadingOverlay.vue';
import ZipModal from "../components/ZipModal.vue";
import PasswordModal from "../components/documentPasswordModal.vue"

export default {
    name: "DownloadView",
    components: { LoadingOverlay, ZipModal, PasswordModal},
    setup () {
        const { cookies } = useCookies();
        return { cookies };
    },
    data: function () {
        return {
            anonymizedFiles: [],
            zipModalOpen: false,
            passwordModal: false,
            zipped_data: null,
            translations: ["filename", "date", "entitylist", "anonymizedfile"],
            message: this.$t("LoadingMessages.downloadLoading"),
            isDocEncrypted: false,
            docName: '',
            isEncrypted: false
        }
    },
    methods: {
        showZipModal(data) {
            this.zip_data = data
			this.zipModalOpen = true;
		},
		closeZipModal() {
			this.zipModalOpen = false;
		},
        showPasswordModal(documentName, docId, encription){
            this.docName = documentName
            this.isEncrypted = encription
            this.documentId = docId
            this.passwordModal = true
        },
        closePasswordModal(){
            var allDone = false
            var interval = setInterval(() => {
                allDone = true
                this.fetchDocuments()
                if (allDone) {
                clearInterval(interval)
            }
            }, 100)
            this.passwordModal = false
            
        },
        explode(text){
            let arr = text.split(" ")
            arr[0] = this.$t("Date."+arr[0])
            arr[1] = this.$t("Date."+arr[1])
            text= arr.join(" ")
            return text
        },
       
        fetchDocuments () {
            this.$store.state.showOverlay = true
            this.axios.get(EnvProvider.value('API_URL') + this.$root.API_GET,
            ).then((response) => {
                this.$store.state.showOverlay = false
                if (response.status === 200) {
                    let files = response.data
                    for (let i = 0; i < files.length; i++) {
                        let daysDiff = Math.floor((new Date() - new Date(files[i].dtcreated)) / (1000*60*60*24))

                        if (daysDiff >= 6){
                            files[i].deletionalert = true
                        } else {
                            files[i].deletionalert = false
                        }
                    }

                    this.anonymizedFiles = files;

                    // this.$store.commit('pushMsg', {type: 'ok', text: this.$t("StoreCommits.downloadSucess")});
                } else {
                    this.$store.commit('pushMsg', {type: 'error', text: this.$t("StoreCommits.downloadFail")});
                    console.error('Bad request');
                }
            }).catch(
                function (error) {
                    if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    console.log(error.response.data);
                    } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    console.log(error.request);
                    } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                    }
                    // this.$store.state.showOverlay = false
                }
            );
        },
        fetchZip () {
            this.$store.state.showOverlay = true
            this.axios({
                url: EnvProvider.value('API_URL') + this.$root.API_ZIP,
                method: "GET",
                responseType: "blob"
            }).then((response) => {
                this.$store.state.showOverlay = false
                if (response.status === 200) {
                    console.log(response)

                    const contentDisposition = response.headers['content-disposition'];
                    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                    const matches = filenameRegex.exec(contentDisposition);
                    let filename = 'all-documents.zip'; // Default filename
                    if (matches != null && matches[1]) {
                        filename = matches[1].replace(/['"]/g, '');
                    }
                    let file = response.data
                    let fileURL = window.URL.createObjectURL(new Blob([file]))
                    let fileLink = document.createElement('a');

                    fileLink.href = fileURL;
                    fileLink.setAttribute('download', filename);
                    document.body.appendChild(fileLink);

                    fileLink.click();
                    document.body.removeChild(fileLink);
                    window.URL.revokeObjectURL(fileURL);

                    this.$store.commit('pushMsg', {type: 'ok', text: this.$t("StoreCommits.zipSucess")})
                } else {
                    this.$store.commit('pushMsg', {type: 'error', text: this.$t("StoreCommits.zipFail")})
                    console.error('Something is wrong, contact your support.')
                }
            })
        },
        fetchSingleDoc(url_, originalfilename){
            this.$store.state.showOverlay = true
            this.axios({
                url: EnvProvider.value('API_URL') + url_,
                method: 'GET',
                responseType: 'blob'
                }
            ).then((response) => {
                this.$store.state.showOverlay = false
                if (response.status === 200) {
                    let params = new URLSearchParams(new URL(EnvProvider.value('API_URL') + url_).search)
                    let fileURL = window.URL.createObjectURL(new Blob([response.data]))
                    console.log(fileURL)
                    
                    let fileLink = document.createElement('a');

                    fileLink.href = fileURL;
                    console.log("Originalfilename", originalfilename);
                    fileLink.setAttribute('download', originalfilename);
                    document.body.appendChild(fileLink);

                    fileLink.click();    
                    document.body.removeChild(fileLink);
                    window.URL.revokeObjectURL(fileURL);

                    this.$store.commit('pushMsg', {type: 'ok', text: this.$t("StoreCommits.downloadSucess")});
                }
            }) 
        },
        fetchDocCSV(url_){
            this.$store.state.showOverlay = true
            this.axios({
                url: EnvProvider.value('API_URL') + url_,
                method: 'GET',
                responseType: 'blob'
                }
            ).then((response) => {
            this.$store.state.showOverlay = false
                if (response.status === 200) {
                    let params = new URLSearchParams(new URL(EnvProvider.value('API_URL') + url_).search)
                    let fileURL = window.URL.createObjectURL(new Blob([response.data]))
                    console.log(response)
                    
                    let fileLink = document.createElement('a');

                    fileLink.href = fileURL;
                    fileLink.setAttribute('download', params.get('filename'));
                    document.body.appendChild(fileLink);

                    fileLink.click();    
                    document.body.removeChild(fileLink);
                    window.URL.revokeObjectURL(fileURL);
                }
            }) 
        },
        deleteFileFromFacade(document) {
            this.axios.get(
                EnvProvider.value('API_URL') + this.$root.API_DELETE + '/' + document.originalfilename
            ).then(response => {
                if (response.status === 200) {
                    this.$store.commit('pushMsg', {type: 'ok', text: this.$t("StoreCommits.document") + document.originalfilename + this.$t("StoreCommits.wasSucessfullyDeleted")})
                    this.fetchDocuments()
                } else {
                    this.$store.commit('pushMsg', {type: 'error', text: this.$t("StoreCommits.deleteFail")})
                    console.error('Bad request')
                }
            })
            return document
        }
    },
    created () {
        // this.fetchDocuments()
        var allDone = false
        var iterator = 0
        var interval = setInterval(() => {
            allDone = true
            this.fetchDocuments()
            if (iterator++ >= 10) {
                allDone = true
            } else {
                for (var index in this.anonymizedFiles) {
                    if (this.anonymizedFiles[index].statusid < 4) {
                        allDone = false
                    }
                }
            }
            if (allDone) {
                clearInterval(interval)
            }
        }, 1000)
    }
}
</script>

<style lang="scss" src="@/assets/css/views/DownloadView.scss"/>
<style lang="scss" src="@/assets/css/DownloadAllFiles.scss"/>