<template>
  <div class="profile-bounding">
    <div class="profile-header">
      <div class="header-container">
        <h1 class="profile-title-text">{{ this.$t("profile.profile") }}</h1>
        <button class="profile-exit-button" @click="closeModal">X</button>
      </div>
      <div class="tabs">
        <div
          class="tab"
          :class="{ active: activeTab === 'tab1' }"
          @click="activeTab = 'tab1'"
        >
          {{ this.$t("profile.statistics.name") }}
        </div>
        <div
          class="tab"
          :class="{ active: activeTab === 'tab2' }"
          @click="activeTab = 'tab2'"
        >
          {{ this.$t("profile.graphs.name") }}
        </div>
        <div
          class="tab"
          :class="{ active: activeTab === 'tab3' }"
          @click="activeTab = 'tab3'"
        >
          {{ this.$t("profile.license.name") }}
        </div>
      </div>
    </div>

    <div class="profile-main-section">
      <div class="profile-content">
        <div class="profile-tabs-content">
          <div class="profile-tab-content" v-if="activeTab === 'tab1'">
            <h2>{{ this.$t("profile.statistics.name") }}</h2>
            <div class="profile-setting-item">
              <span>{{ this.$t("profile.statistics.dataView") }}</span>
              <div class="segmented-control">
                <button
                  class="left"
                  :class="{ active: toggleGlobalData }"
                  @click="
                    toggleGlobalData = true;
                    loadData();
                  "
                >
                  {{ this.$t("profile.statistics.global") }}
                </button>
                <button
                  class="right"
                  :class="{ active: !toggleGlobalData }"
                  @click="
                    toggleGlobalData = false;
                    loadData();
                  "
                >
                  {{ this.$t("profile.statistics.user") }}
                </button>
              </div>
            </div>
            <div v-if="loadingError" class="error-message">
              {{ loadingError }}
            </div>
            <div v-else>
              <div
                v-for="(stat, statIndex) in statData"
                :key="statIndex"
                class="statistic"
              >
                <span class="stat-name">{{ stat.statName }}:</span>
                <span class="stat-value">{{ stat.statValue }}</span>
              </div>
            </div>
          </div>

          <div class="profile-tab-content" v-if="activeTab === 'tab2'">
            <h2>{{ this.$t("profile.graphs.name") }}</h2>
            <div class="profile-setting-item">
              <span> {{ this.$t("profile.graphs.dataView") }} </span>
              <div class="segmented-control">
                <button
                  class="left"
                  :class="{ active: toggleGlobalData }"
                  @click="
                    toggleGlobalData = true;
                    loadData();
                  "
                >
                  {{ this.$t("profile.statistics.global") }}
                </button>
                <button
                  class="right"
                  :class="{ active: !toggleGlobalData }"
                  @click="
                    toggleGlobalData = false;
                    loadData();
                  "
                >
                  {{ this.$t("profile.statistics.user") }}
                </button>
              </div>
            </div>
            <div v-if="loadingError" class="error-message">
              {{ loadingError }}
            </div>
            <div v-else class="chart-container">
              <BarChart
                :chartData="barChartData"
                :chartOptions="chartOptions"
              />
              <PieChart
                :chartData="pieChartData"
                :chartOptions="chartOptions"
              />
            </div>
          </div>
          <div class="profile-tab-content" v-if="activeTab === 'tab3'">
            <h2>{{ this.$t("profile.license.name") }}</h2>
            <div v-if="licenseInfo">
              <div v-if="licenseInfo.expiration_date" class="statistic">
                <span class="stat-name">{{
                  this.$t("profile.license.expiration")
                }}</span>
                <span class="stat-value">{{
                  licenseInfo.expiration_date
                }}</span>
              </div>
              <div v-if="licenseInfo.max_docs" class="statistic">
                <span class="stat-name">{{
                  this.$t("profile.license.maxDocs")
                }}</span>
                <span class="stat-value">{{ licenseInfo.max_docs }}</span>
              </div>
              <div v-if="licenseInfo.max_users" class="statistic">
                <span class="stat-name">{{
                  this.$t("profile.license.maxUsers")
                }}</span>
                <span class="stat-value">{{ licenseInfo.max_users }}</span>
              </div>
              <div class="statistic-divider"></div>
              <div v-if="licenseInfo.days_left" class="statistic">
                <span class="stat-name">{{
                  this.$t("profile.license.daysLeft")
                }}</span>
                <span class="stat-value">{{ licenseInfo.days_left }}</span>
              </div>
              <div v-if="licenseInfo.docs_left" class="statistic">
                <span class="stat-name">{{
                  this.$t("profile.license.docsLeft")
                }}</span>
                <span class="stat-value">{{ licenseInfo.docs_left }}</span>
              </div>
              <div v-if="licenseInfo.users_left" class="statistic">
                <span class="stat-name">{{
                  this.$t("profile.license.usersLeft")
                }}</span>
                <span class="stat-value">{{ licenseInfo.users_left }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BarChart from "./Charts/BarChart.vue";
import PieChart from "./Charts/PieChart.vue";

import EnvProvider from "jvjr-docker-env";
import { useCookies } from "vue3-cookies";

export default {
  name: "ProfileModal",
  components: {
    BarChart,
    PieChart,
  },
  props: {
    isVisible: {
      type: Boolean,
      required: true,
    },
  },
  setup() {
    const { cookies } = useCookies();
    return { cookies };
  },
  data() {
    return {
      loadingError: null,
      toggleGlobalData: false,
      activeTab: "tab1",
      licenseInfo: {},
      statData: [],
      barChartData: {
        data: [],
        layout: {
          title:
            "Daily Processed Documents in the Last " +
            EnvProvider.value("LAST_X_DAYS_STAT") +
            " Days",
          xaxis: { title: "Date" },
          yaxis: { title: "Count" },
        },
      },
      pieChartData: {
        data: [],
        layout: {
          title: "Document Types Distribution",
        },
      },
      chartOptions: {
        responsive: true,
        displayModeBar: false,
      },
    };
  },
  methods: {
    closeModal() {
      this.$emit("close-modal"); // Emit an event when the close button is clicked
    },
    getLastXDays(x) {
      const dates = [];
      const currentDate = new Date();

      for (let i = 0; i < x; i++) {
        const date = new Date();
        date.setDate(currentDate.getDate() - i);
        dates.unshift(this.formatDate(date));
      }

      return dates;
    },
    formatDate(date) {
      return date.toISOString().split("T")[0];
    },
    async loadData() {
      this.loadingError = null;

      // Get license info
      try {
        this.licenseInfo = this.cookies.get("licenseData");
      } catch (error) {
        console.error("Error loading license data:", error);
      }

      // Get stat data
      try {
        const response = await this.axios.get(
          EnvProvider.value("API_URL") + this.$root.API_GET_STATS,
          { withCredentials: true }
        );
        const data = response.data;

        // Update text data
        this.statData = this.toggleGlobalData
          ? data.global_statistics.text_data
          : data.user_statistics.text_data;

        // Update bar chart data
        const dailyProcessed = this.toggleGlobalData
          ? data.global_statistics.graph_data.daily_processed_last_month
          : data.user_statistics.graph_data.daily_processed_last_month;
        const allDates = this.getLastXDays(
          EnvProvider.value("LAST_X_DAYS_STAT")
        );

        const completeData = allDates.reduce((acc, date) => {
          acc[date] = dailyProcessed[date] || 0;
          return acc;
        }, {});

        this.barChartData = {
          data: [
            {
              x: Object.keys(completeData),
              y: Object.values(completeData),
              type: "bar",
            },
          ],
          layout: {
            title:
              "Daily Processed Documents in the Last " +
              EnvProvider.value("LAST_X_DAYS_STAT") +
              " Days",
            xaxis: { title: "Date" },
            yaxis: { title: "Count" },
          },
        };

        // Update pie chart data
        const pieData = this.toggleGlobalData
          ? data.global_statistics.graph_data.pie_chart_data
          : data.user_statistics.graph_data.pie_chart_data;
        this.pieChartData = {
          data: [
            {
              values: [
                pieData.zipped_documents,
                pieData.encrypted_documents,
                pieData.total_documents -
                  (pieData.encrypted_documents + pieData.zipped_documents),
              ],
              labels: [
                "Zipped Documents",
                "Encrypted Documents",
                "Unencrypted Documents",
              ],
              type: "pie",
            },
          ],
          layout: {
            title: "Document Types Distribution",
          },
        };
      } catch (error) {
        console.error("Error loading data:", error);
        this.barChartData = {
          data: [],
          layout: {
            title:
              "Daily Processed Documents in the Last " +
              EnvProvider.value("LAST_X_DAYS_STAT") +
              " Days",
            xaxis: { title: "Date" },
            yaxis: { title: "Count" },
          },
        };
        this.pieChartData = {
          data: [],
          layout: {
            title: "Document Types Distribution",
          },
        };
        this.loadingError =
          "Failed to load statistics. Please try again later.";
        this.$store.commit("pushMsg", {
          type: "error",
          text: this.loadingError,
        });
      }
    },
  },
  watch: {
    isVisible(newValue) {
      if (newValue) {
        this.loadData();
      }
    },
  },
  mounted() {
    this.loadData();
  },
};
</script>

<style lang="scss" scoped src="@/assets/css/ProfileModal.scss"></style>
