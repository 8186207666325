<template>
    <div class="backdrop">
        <div class="modal">
            <header class="header">
                <slot v-if="!this.isEncrypted" name="header">{{ $t("downloadview.encryptDocument") }}</slot>
                <slot v-else name="header">{{ $t("downloadview.decryptDocument") }}</slot>
                <button type="button" class="btn-close" @click="close">x</button>
            </header>
            <div v-if="!this.isEncrypted">
                <section class="body">
                    <label>{{ $t("downloadview.passwordDoc") }}</label>
                    <input type="text" id="password" style="-webkit-text-security: disc;" class="passwordName"
                        v-model="pass">
                    <label>{{ $t("downloadview.confirmPasswordDoc") }}</label>
                    <input class="passwordName" @keyup="confirmPassword" type="text" id="password"
                        style="-webkit-text-security: disc;" v-model="passConfirm">
                    <label id="invalidPass" v-if="invalidPasswords">{{
                    $t("downloadview.passwordDoesNotMatch")
                }}</label>
                </section>
            </div>

            <div v-else>
                <section class="body">
                    <label>{{ $t("downloadview.passwordDoc") }}</label>
                    <input type="text" id="password" style="-webkit-text-security: disc;" class="passwordName"
                        v-model="passConfirm">
                </section>
            </div>
            <button id="confirmButton" @click="confirmButton" :disabled="isSaveDisabled" type="submit">{{
                    $t("downloadview.confirmButton") }}</button>
        </div>
    </div>
</template>

<script>
import EnvProvider from 'jvjr-docker-env';

export default {
    name: 'PasswordModal',
    props: {
        docName: String,
        isEncrypted: Boolean,
        documentId: String
    },
    data() {
        return {
            pass: '',
            passConfirm: '',
            invalidPasswords: false,
            sibmitDisabled: false
        }
    },
    computed: {
        isSaveDisabled() {
            return this.invalidPasswords || this.isEmpty;
        },
        isEmpty() {
            return this.passConfirm.trim() === '';
        },
    },
    methods: {
        close() {
            this.$emit('close');
        },
        confirmButton() {

            let formData = new FormData()
            formData.append('docId', this.documentId)
            formData.append('document_name', this.docName)
            formData.append('pswd', this.passConfirm)

            if (!this.isEncrypted) {
                this.axios.post(EnvProvider.value('API_URL') + this.$root.API_ENCRYPT_DOC, formData
                ).then((response) => {
                    if (response.status === 200) {
                        console.log('PDF encrypted successfuly')
                        this.$store.commit('pushMsg', { type: 'ok', text: this.$t("StoreCommits.documentEncryptSuccess") })
                        this.close()
                    }
                })
            } else {
                this.axios.post(EnvProvider.value('API_URL') + this.$root.API_DECRYPT_DOC, formData
                ).then((response) => {
                    if (response.status === 200) {
                        console.log('PDF decrypted successfuly')
                        this.$store.commit('pushMsg', { type: 'ok', text: this.$t("StoreCommits.documentDecryptionSuccess") })
                        this.close()
                    }
                }).catch((error) => {
                    if (error.response.status === 422) {
                        this.$store.commit('pushMsg', { type: 'error', text: this.$t("StoreCommits.wrongPassword") })
                    } else {
                        console.log(error)
                    }

                })

            }
        },
        confirmPassword() {
            if (this.pass !== this.passConfirm) {
                this.invalidPasswords = true
                this.submitDisabled = true
            } else {
                this.invalidPasswords = false
                this.submitDisabled = false
            }
        },
    },
};
</script>

<style scoped>
.header {
    position: relative;
    margin-top: 0.2rem;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #eeeeee;
    margin-left: 1rem;
    align-items: center;
}

.body {
    position: relative;
    padding: 20px 20px;
    overflow-x: auto;
}

/* .modal {
    height: 43%
} */

.backdrop {
    position: fixed;
    z-index: 1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
}

.passwordName {
    min-width: 350px;
    border: none;
    background: #F4F4F4;
    border-radius: 16px;
    padding-inline: 1rem;
    width: -webkit-fill-available;

}

label {
    display: flex;
    padding: 10px;
}


#confirmButton {
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    background: #00A75A;
    border: 1px solid #4AAE9B;
    border-radius: none;
    padding: 10px;
    margin-top: auto;
    margin-bottom: 20px;
    border-radius: 16px;
    height: 50px;
    width: 30%;
    margin-left: 33%;

    &:hover {
        cursor: pointer;
        background-color: #63b858;
    }
}

#invalidPass {
    color: red;
}

@media screen and (max-height: 700px) {
    .body {
        position: relative;
        padding: 15px 15px;
        overflow-x: auto;
    }

    .modal {
        height: 54.5%
    }
}
</style>

<!-- TEST BUILD 15.04.2024 -->