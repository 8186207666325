<template>
  <div>
    <header class="templates-modal-header">
      {{ this.$t("formview.SaveTemplate") }}
      <button type="button" class="templates-btn-close" @click="close">x</button>
    </header>

    <section class="templates-modal-body">
      <h2 class="templates-modal-title">{{ this.$t("templateModal.TemplateName") }}</h2>
      <input type="text" id="template_name" v-model="enteredTemplateName" class="templates-input">
      <p v-if="showValidationMessage" class="templates-validation-message">{{ $t("preset.usedName") }}</p>
      <p v-if="isEmpty" class="templates-validation-message">{{ $t("preset.isEmpty") }}</p>
    </section>

    <footer class="templates-modal-footer">
      <button type="button" class="templates-btn-green" @click="saveTemplate" :disabled="isSaveDisabled">{{$t("preset.savePreset")}}</button>
    </footer>
  </div>
</template>



<script>
export default {
  name: 'templateNameModal',  
  emits: ["close","save-template"],
  props: {
    templateNames: Array,
    templates: Array,
  },
  data() {
    return {
      enteredTemplateName: '',
      maxLength: 40
    };
  },
  computed: {
    isSaveDisabled() {
      return !this.isValidTemplateName || this.isEmpty;
    },
    isValidTemplateName() {
      return !this.templateNames.includes(this.enteredTemplateName);
    },
    showValidationMessage() {
      return !this.isValidTemplateName && this.enteredTemplateName.length > 0;
    },
    isEmpty() {
      return this.enteredTemplateName.trim() === '';
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
    saveTemplate() {
      this.close();
      this.$emit('save-template', this.enteredTemplateName);
      this.enteredTemplateName = ''; // Clear the input after saving
    },
  },
};
</script>

<style lang="scss" scoped src="@/assets/css/templateNameModal.scss" />

<!-- <style scoped>
.validation-message {
  color: red;
  font-size: 12px;
  padding-left: 30px;
  height: 15px;
  width: 200px;
  margin: 0;
  margin-top: 5px;
}

.modal-body{
  height: 72px;
  padding-bottom: 0;
}

input {
  min-width: 350px;
    border: none;
    background: #F4F4F4;
    border-radius: 16px;
    padding-inline: 1rem;
    width: -webkit-fill-available;

}
</style> -->
