<template>
    <div class="modal-backdrop" @click="closeIfClickedOutside">
      <div class="modal">
        <header class="modal-header">
          <slot name="header">{{$t("Download.modalWindow")}}</slot>
          <button type="button" class="btn-close" @click="close">x</button>
        </header>
        
        <section class="modal-body">
          {{ console.log(zip_files) }}
          <div class="templateBox" v-for="file in zip_files">
           <div>
              {{ file }}
           </div> 
          </div>
        </section>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'ZipModal',
    props: {
      zip_files: Array,
    },
    methods: {
      close() {
        this.$emit('close');
      },
      closeIfClickedOutside(event) {
      // Check if the click event originated from within the modal
        if (!event.target.closest('.modal')) {
          this.close();
        }}
    },
  };
  </script>
  
  <style scoped>
  .templateBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px;
    padding: 10px;
    border-radius: 10px;
    border: 0.5px solid #cccccc74;
    background-color: #f4f4f4;
    position: relative;
  }
  
  .additionalInfo {
    position: fixed;
    background-color: white;
    border: 1px solid #ddd;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0px 2px 5px rgba(0,0,0,0.2);
    z-index: 1000;
    width: auto;
    max-width: 30%;
    max-height: 80%;
    overflow: auto;
    word-wrap: break-word;
  }
  
  .additionalInfo div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .templateBox:hover .additionalInfo {
    display: block;
  }

  .modal-header {
    font-weight: 600;
    color: #2156A4;
  }
  </style>