<template>
    <div class="modal-backdrop">
        <div class="modal">
            <header class="modal-header">
                <h1 class="ocrHelpHeader">Ocr Help</h1>
                <button type="button" class="btn-close" @click="close">x</button>
            </header>

            <section class="modal-body-ocr">
                <div>
                    <p>{{ $t('ocrHelpModal.1thP') }}</p>
                    <p>{{ $t('ocrHelpModal.2ndP') }}</p>
                    <p>{{ $t('ocrHelpModal.3rdP') }}</p>
                </div>
                <br>
                <div>
                    <h3>{{ $t('ocrHelpModal.autoOcr') }}</h3>
                    <p>{{ $t('ocrHelpModal.4thP') }}</p>
                    <h3>OCR</h3>
                    <p>{{ $t('ocrHelpModal.5thP') }}</p>
                    <h3>{{ $t('ocrHelpModal.WoOcr') }}</h3>
                    <p>{{ $t('ocrHelpModal.6thP') }}</p>
                </div>
            </section>

            <footer class="modal-footer">
                <!-- Optional footer content -->
            </footer>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ocrHelpModal',
    methods: {
        close() {
            this.$emit('close');
        },
    },
};
</script>

<style lang="scss" src="@/assets/css/OcrHelpModal.scss" />