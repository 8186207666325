<template>
    <div class="flexContainer">
    <div class="flexUpload" ref="flexUpload"  @dragover="highlighting" @dragleave="dropLeave" @dragend="dropLeave" @dragover.prevent="" @drop.prevent="handleDrop">

      <div class="flexUploadTitle">
          <img src="../assets/iconUpload.svg"  height="64" alt=""/>
          <h1> {{ $t("upload.anonymizationUpload") }} </h1>
      </div>
    
      <div class="uploadDocument">
      <!-- <p> {{ $t($root.importedFileName) }} </p> -->
        <div class="uploadSection" ref="uploadSection">
            <input type="file" ref="file" style="display: none" @change="changeFile()" :accept="allowedTypeFiles" multiple>
            <button class="pad" @click="selectFile()"> {{ $t("upload.fileChose") }} </button>
            <div class="dragDropBox" ref="dragDropBox" >
                <p class="text"> {{$t("downloadview.dragAndDrop")}} </p>
            </div>
        </div>
    </div>
    </div>
    </div>
</template>


<script>
import store from "../store"
import { useCookies } from 'vue3-cookies'
export default {
  data () {
        return {
            fileName: (store.state.arrayOfDocuments != null ) ? store.state.arrayOfDocuments.name : this.$t("upload.noFileChosen"),
            importedFile: {},
            allowedTypeFiles: '.pdf,.doc,.docx,.txt,.rtf,.jpeg,.jpg,.png',  // use lowercase
                // allowedTypeFiles: '.doc,.docx,.pdf,.rtf,.html,.xls,.xlsx,.csv,.txt,.odt,.odm'
        }
      },
      setup() {
        const { cookies } = useCookies();
        return { cookies };
        },
      methods:{
        selectFile () {
            if (this.$store.state.loggedUser) {
                let fileInputElement = this.$refs.file
                fileInputElement.click()
            } else {
                this.$store.commit('pushMsg', {type: 'error', text: this.$t("StoreCommits.notLoggedIn")})
            }
        },
        changeFile () {
            this.importedFiles = this.$refs.file.files
            console.log(this.$refs.file.files)
            if (this.checkFileType(this.importedFiles)) {
                this.$root.importedFileName = this.importedFiles[0].name
                this.$store.commit('setDocuments', this.importedFiles)
                this.$router.push({
                    name: 'FormView',
                    params: {file: this.$refs.file.files}
                })
            } else {
                this.showModal = true
            }
        },
        checkFileType (testingFiles) {
            for(let i=0; i < testingFiles.length; i++){
                let file = testingFiles[i]
                let fileExt = this.getExtension(file.name)
                this.allowedTypeFiles.split(',').forEach((element)=>{
                if (element.replace('.', '') != fileExt){
                    return false
                }})
            }
            return true
        },
        getExtension (filename) {
            var parts = filename.split('.')
            return String(parts[parts.length - 1]).toLowerCase()
        },
        highlighting (event){
            setTimeout(() => {
                this.$refs.flexUpload.classList.add('drop_area_highlight');
                this.$refs.dragDropBox.classList.add('drop_area_highlight');
            }, 1)
            
        },
        dropLeave (event){
            const fromElement = event.relatedTarget || event.toElement;
            const isLeavingUploadSection = !fromElement || !this.$refs.flexUpload.contains(fromElement);

            if (isLeavingUploadSection) {
                event.currentTarget.classList.remove('drop_area_highlight');
                this.$refs.dragDropBox.classList.remove("drop_area_highlight");
            }
        }
        ,
        handleDrop(event) {
            //dragleave()
            this.importedFiles = event.dataTransfer.files
            if (this.checkFileType(this.importedFiles)) {
                this.$root.importedFileName = this.importedFiles[0].name
                this.$store.commit('setDocuments', this.importedFiles)
                this.$router.push({
                name: 'FormView',
                params: { file: this.importedFiles }
                })
            } else {
                this.showModal = true
            }
    }
    }
}
</script>

<style lang="scss" src="@/assets/css/views/HomeView.scss"/>
<style lang="scss" src="@/assets/css/FileUpload.scss"></style>