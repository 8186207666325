<template>
  <div ref="pieChart"></div>
</template>

<script>
import Plotly from 'plotly.js-dist';

export default {
  name: 'PieChart',
  props: {
    chartData: {
      type: Object,
      required: true
    },
    chartOptions: {
      type: Object,
      required: true
    }
  },
  mounted() {
    this.renderChart();
  },
  methods: {
    renderChart() {
      Plotly.newPlot(this.$refs.pieChart, this.chartData.data, this.chartData.layout, this.chartOptions);
    }
  },
  watch: {
    chartData(newValue) {
      if (newValue) {
        this.renderChart();
      }
    },
    chartOptions(newValue) {
      if (newValue) {
        this.renderChart();
      }
    }
  }
};
</script>