import { createWebHistory, createRouter } from "vue-router";
import HomeView from '../views/HomeView.vue'
import FormView from "../views/FormView.vue"
import DownloadView from "../views/DownloadView.vue"
import ManualAnnotationView from "../views/ManualAnnotationView.vue"
import EnvProvider from 'jvjr-docker-env';
import axios from 'axios';
import { useCookies } from "vue3-cookies";
import store from '../store/index.js';

const { cookies } = useCookies();

const routes = [
{
  path: '/homeview',
  name: 'HomeView',
  component: HomeView,
  meta:{requiresAuth:true},
},
{
  path: "/formview",
  name: "FormView",
  component: FormView,
  meta:{requiresAuth:true},
},
{
  path: "/downloadview",
  name: "DownloadView",
  component: DownloadView,
  meta:{requiresAuth:true},
},
{
  path: "/manualannotationview",
  name: "ManualAnnotationView",
  component: ManualAnnotationView,
  meta:{requiresAuth:true},
  props: true
},
{
  path: "/:pathMatch(.*)*",
  component: HomeView,
  meta:{requiresAuth:true},
},
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

async function auth(){
  console.log('Startin auth')
  try {
    if (EnvProvider.value('API_AUTH') == undefined) return false;
    const response = await axios.get(EnvProvider.value('API_AUTH') + '/auth', {withCredentials: true});
    if (response.status === 200){
      if (response.data.logged_in){
        console.log('true')
        return true
      } else {
        console.log('false')
        return false
      }
    } else {
        console.log('false')
        return false
    }
  } catch(err){
    console.log(err)
    return false
  }
}

function setLoggedUser(){
  let user = cookies.get('userName')
  if(user != null){
    cookies.set('userName', user, EnvProvider.value('COOKIE_DURATION'), null, EnvProvider.value('HOST_DOMAIN'))
    store.commit('setLoggedUser', user)
  }
  else {
    store.commit('setLoggedUser', '')
    console.log('Failed to set username from cookie')
    if (EnvProvider.value('LOCAL') === 'true' && EnvProvider.value('ENV') === 'DEV') {
      store.commit('setLoggedUser', 'testuser')
    }
  }
}

// Authentication logic on routes
router.beforeEach(async (to, from, next) => {
  if (to.meta && to.meta.requiresAuth === false) {
    next() // requires auth is explicitly set to false
  } else if (await auth()) {
    setLoggedUser()
    next() // i'm logged in. carry on
  } else {
    // unauthorized, redirect to login menu
    let home = new URL("/login-page", EnvProvider.value('API_AUTH'));
    window.location.href = home;
  }
})
export default router
