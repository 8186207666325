<template>
  <div class="headerFlex">
    <div class="container">
      <div class="logo" v-on:click="prelinkToMinex()">
        <img src="../assets/minexLogo.png" class="imgLogo" height="" alt="" />
      </div>
      <div class="profileMenu">
        <a v-if="isManualAnnotRoute" href="javascript:void(0)" class="helpLink" @click="toggleHelpModal">{{ $t("header.help") }}</a>
        <teleport to="body">
          <div class="modalHelp" v-show="helpModalShow" @click="handleClick">
            <div class="modalViewHelp">
              <helpModal @close-modal="helpModalShow = false"/>
            </div>
          </div>
        </teleport>
        <div class="profileSection">
          <a href="javascript:void(0)" v-if="$store.state.loggedUser != ''" @click="$router.push('/downloadview')"> {{
            $t("header.documents") }} </a>
          <img class="userIcon" src="../assets/userIcon.png" @click="toggleProfileModal" alt="" />
          <a href="javascript:void(0)" @click="toggleProfileModal"> {{ $store.state.loggedUser }} </a>
          <teleport to="body">
            <div class="modalProfile" v-show="profileModalShow" @click="handleClick">
              <div class="modalViewProfile">
                <profileModal @close-modal="profileModalShow = false" :isVisible="profileModalShow"/>
              </div>
            </div>
          </teleport>
          <a href="javascript:void(0)" class="p-logout" @click="logoutUser()"> {{
            $t("header.logout") }} </a>
        </div>
        <select @change="updateLanguage()" v-model="$i18n.locale" class="select">
          <option class="localeButton" v-for="(lang, i) in langs" :key="`Lang${i}`" :value="lang">
            <p @click="$i18n.locale = lang"> {{ lang }} </p>
          </option>
        </select>
      </div>
    </div>
  </div>
  <!-- notification bar -->
  <transition>
    <info v-if="($store.state.msg.length > 0)" />
  </transition>
  <!-- <info :csstype="ok" /> -->
  <!-- csstype can be 'ok', 'warn', 'error' -->
  <!-- end of notification bar -->
  <!-- <div class="headerLine"></div> -->
  <!-- <login v-show="loginVisible" @close="closeLogin" /> -->
</template>

<script>
// import Login from './Login.vue';
import Info from './Info.vue';
import HelpModal from "../components/HelpModal.vue"
import ProfileModal from "../components/ProfileModal.vue"
import { useCookies } from 'vue3-cookies';
import EnvProvider from 'jvjr-docker-env';

export default {
  name: 'Header',
  components: {
    HelpModal,
    ProfileModal,
    Info,
  },
  setup() {
    const { cookies } = useCookies();
    return { cookies };
  },
  data() {
    return {
      // loginVisible: false,
      loggedUser: '',
      helpModalShow: false,
      profileModalShow: false,
      langs: ["sk", "cz", "en", "de", "hu"]
    }
  },
  mounted() {
    let u = this.cookies.get('userName')
    this.loggedUser = (u != null) ? u : ''
    this.$store.state.loggedUser = (u != null) ? u : ''

    if (localStorage.getItem("lang")) {
      this.$i18n.locale = localStorage.getItem("lang");
    } else {
      localStorage.setItem("lang", this.$i18n.locale);
    }

  },
  computed: {
    isManualAnnotRoute() {
      return this.$route.path === '/manualannotationview';
    }
  },
  methods: {
    // showLogin() {
    //   this.loginVisible = true;
    // },
    // closeLogin() {
    //   this.loginVisible = false;
    // },
    toggleHelpModal() {
      this.helpModalShow = !this.helpModalShow;
    },
    toggleProfileModal() {
      this.profileModalShow = !this.profileModalShow;
    },
    handleClick(e) {
      if (e.target.classList.contains("modalHelp")) {
        this.helpModalShow = false; // Update this line
      }
      if (e.target.classList.contains("modalProfile")) {
        this.profileModalShow = false; 
      }
    },

    handleFocusOut() {
      this.classList.add('open');
    },
    logoutUser() {
      console.log('Logging out user');
      this.axios.get(EnvProvider.value('API_AUTH') + this.$root.API_LOGOUT, {withCredentials: true}
      ).then((result) => {
        console.log(result.data);
        console.log('Response data: ' + result.data);
        if (result.status == 200) {
          this.cookies.remove('userName', null, EnvProvider.value('HOST_DOMAIN'));
          this.cookies.remove('csrfToken');
          this.$store.commit('setDocuments', null);
          this.$store.commit('setLoggedUser', '');
          this.$store.commit('pushMsg', { type: 'ok', text: this.$t("StoreCommits.sucessfullLogout") });
          this.$router.go(0);
        } else {
          this.$store.commit('pushMsg', { type: 'error', text: this.$t("StoreCommits.processingFailed") })
        }
      }).catch((e) => {
        console.log(e);
        // setResponse("This is an error from catch()");
      });
      console.log('After log out')

    },
    prelinkToMinex() {
      window.location.href = EnvProvider.value('HOME_URL')
    },
    updateLanguage() {
      localStorage.setItem("lang", this.$i18n.locale);
    },

  },
};
</script>

<style scoped lang="scss" src="@/assets/css/Header.scss" />
